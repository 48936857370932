import { TEL_1 } from '@utils/constants'
import React from 'react'

import Image from 'next/image'

const WhatsAppButton = () => {
    return (
        <a
            href={`https://api.whatsapp.com/send?phone=${TEL_1.formatted_country_code}`}
            className="fixed right-4 bottom-4"
        >
            <Image
                src="/whatsapp.png"
                width={70}
                height={70}
                alt="whatsapp"
                quality={100}
            />
        </a>
    )
}

export default WhatsAppButton
